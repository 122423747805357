<template>
  <v-container class="is-md py-12">
    <!-- header -->
    <v-row align="center" class="mb-3">
      <v-col cols="6">
        <v-btn
          plain small :ripple="false" text
          to="/items/search"
          class="unwidth px-0"
        >
          <v-icon class="mr-1">
            mdi-keyboard-backspace
          </v-icon>
          Back to items
        </v-btn>
      </v-col>

      <v-col cols="6" class="text-right">
        <v-chip label color="primary--gradient" light>
          <span class="font-weight-semibold text-uppercase">Creating new item</span>
        </v-chip>
      </v-col>
    </v-row>

    <ItemForm
      v-if="formDataReady"
      :value="forms"
      :markets="markets"
      :rarities="rarities"
      :categories="categories"
      :loading="loading.submit"
      is-new
      @submit="onSubmit"
    />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemForm from './components/ItemForm'

export default {
  metaInfo: {
    title: 'Items',
  },
  components: {
    ItemForm,
  },
  data() {
    return {
      loading: {
        submit: false,
        data: false,
      },
      rarities: [],
      categories: [],
      markets: [],
      forms: {
        item: {
          name: null,
          brand: null,
          category: null,
          description: null,
          type: null,
          canWithdraw: false,
          canOrder: false,
          canSell: true,
          canSwap: true,
        },
        variants: [],
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
    }),
    formDataReady() {
      return !!this.markets.length && !!this.categories.length && !!this.rarities.length
    },
  },
  async created() {
    this.getFormData()
  },
  methods: {
    async getFormData() {
      try {
        this.loading.data = true
        const [markets, categories, rarities] = await Promise.all([
          this.$socket.request('admin.markets.get'),
          this.$socket.request('admin.items.categories'),
          this.$socket.request('admin.items.rarities'),
        ])

        this.markets = markets
        this.categories = categories
        this.rarities = rarities
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.data = false
      }
    },
    async onSubmit(forms) {
      if (this.loading.submit) return
      this.loading.submit = true

      try {
        await this.$socket.request('admin.items.create', forms)

        this.$toast.success('Item created successfully')
        this.$router.push('/items/search')
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading.submit = false
      }
    },
  },
}
</script>
